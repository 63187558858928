<template>
  <div class="home-container">
    <div class="home-container1">
      <div class="home-hero">
        <div class="home-hero-text-container">
          <h1 class="home-heading Section-Heading">Viitaniemi.info päivittyy</h1>
          <span class="home-text">
            <span class="home-text1">
              Sivut saavat uuden alustan ja ilmeen pian
            </span>
            <br />
            <span>Lokakuu 2023</span>
            <br />
          </span>
        </div>
        <img alt="image" src="/kuvaviitaniemi-1500w.jpg" class="home-image" />
      </div>
    </div>
    <div class="home-footer-container">
      <div class="home-footer">
        <div class="home-copyright-container">
          <svg viewBox="0 0 1024 1024" class="home-icon">
            <path
              d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"
            ></path>
          </svg>
          <span class="Anchor">2023</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Portfolio Page',
    meta: [
      {
        property: 'og:title',
        content: 'Portfolio Page',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f1f1;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #f1f1f1;
}
.home-hero-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: #040404;
  width: 600px;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-image {
  width: 40%;
  object-fit: cover;
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .home-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-hero-text-container {
    width: 80%;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading {
    text-align: center;
  }
  .home-text {
    text-align: center;
  }
  .home-text1 {
    text-align: center;
  }
  .home-image {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-hero-text-container {
    width: 100%;
  }
}
</style>
